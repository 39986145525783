import axiosInstance from "src/helpers/axiosInstance";
import { format } from 'date-fns'




export const getDashboardWidgets = (props) => onResponse => {
    try {
        let base_url = 'dashboard/retrive-dashboard-reports?'

        if (props?.ist !== 'null' && props?.ist !== null) {
            base_url += `sd=${format(props?.ist[0], 'yyyy-M-dd')}&`
            base_url += `ed=${format(props?.ist[1], 'yyyy-M-dd')}&`
        }
        if (props?.month !== 'null' && props?.month !== null && props?.month !== undefined) {
            base_url += `m=${format(props?.month, 'M')}&`
            base_url += `y=${format(props?.month, 'y')}&`
        }


        if (props?.report_date !== 'null' && props?.report_date !== null) {
            base_url += `rsd=${format(props?.report_date[0], 'yyyy-M-dd')}&`
            base_url += `red=${format(props?.report_date[1], 'yyyy-M-dd')}&`
        }


        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {
        console.log(error)
    }

};
export const getDashboardWidgetsGraph = (props) => onResponse => {
    try {
        let base_url = 'dashboard/retrive-dashboard-daily-reports?'

        if (props?.ist !== 'null' && props?.ist !== null) {
            base_url += `sd=${format(props?.ist[0], 'yyyy-M-dd')}&`
            base_url += `ed=${format(props?.ist[1], 'yyyy-M-dd')}&`
        }
        if (props?.month !== 'null' && props?.month !== null && props?.month !== undefined) {
            base_url += `m=${format(props?.month, 'M')}&`
            base_url += `y=${format(props?.month, 'y')}&`
        }


        if (props?.report_date !== 'null' && props?.report_date !== null) {
            base_url += `rsd=${format(props?.report_date[0], 'yyyy-M-dd')}&`
            base_url += `red=${format(props?.report_date[1], 'yyyy-M-dd')}&`
        }


        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getDashboardWidgetsGraph1 = (props) => onResponse => {
    try {
        let base_url = 'dashboard/retrive-dashboard-monthly-reports?'

        if (props?.ist !== 'null' && props?.ist !== null) {
            base_url += `sd=${format(props?.ist[0], 'yyyy-M-dd')}&`
            base_url += `ed=${format(props?.ist[1], 'yyyy-M-dd')}&`
        }
        if (props?.month !== 'null' && props?.month !== null && props?.month !== undefined) {
            base_url += `m=${format(props?.month, 'M')}&`
            base_url += `y=${format(props?.month, 'y')}&`
        }


        if (props?.report_date !== 'null' && props?.report_date !== null) {
            base_url += `rsd=${format(props?.report_date[0], 'yyyy-M-dd')}&`
            base_url += `red=${format(props?.report_date[1], 'yyyy-M-dd')}&`
        }


        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const keepAliveApi = () => onResponse => {
    try {
        let base_url = 'users/keep-alive'
        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getSyncTimeApi = () => onResponse => {
    try {
        let base_url = 'dashboard/get-sync-time'
        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};

export const getNonRevarcData = (props) => onResponse => {
    try {
        let base_url = 'file-monitor/job-monitor/stats?'
        if (props?.report_date !== 'null' && props?.search?.date !== null) {
            base_url += `rsd=${format(props?.search?.date[0], 'yyyy-M-dd')}&`
            base_url += `red=${format(props?.search?.date[1], 'yyyy-M-dd')}&`
        }
        base_url += `page=${props?.query.page}&limit=${props?.query.pageSize}&sort_key=${props?.search.sort_key}&sort_order=${props?.search.sort_order}&client_name=${props?.search.client_name}&facility_name=${props?.search.facility_name}&lockbox_file_name=${props?.search.file_name}&lockbox_number=${props?.search.lockbox_no}&exclude_completed=${props?.search.is_exclude}`

        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getExceptionReprtData = (props) => onResponse => {
    try {
        let base_url = `file-monitor/job-monitor/exception-report?lockbox_id=${props?.lockbox_id}`

        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};
export const getUserRecoveryCode = (props) => onResponse => {
    try {
        let base_url = 'users/get-user-recovery-codes'

        axiosInstance.post(base_url,{user:props?.user})
            .then((response) => {
                onResponse(response);
            })
            .catch((err) => {
                onResponse(err.response);
            });

    } catch (error) {

    }

};

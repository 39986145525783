import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useOutlet } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { LOGIN, NON_REVARC_DASHBOARD, PROFILES } from 'src/constants/routeName';
import Header from './Header';
import NavBar from './NavBar';
import { decryptResponse } from 'src/components/common/EncryptDecrypt';
import { keepAliveApi } from 'src/context/actions/dashboard';
import Swal from 'sweetalert2';



const PrivateLayout = ({ heading, sync }) => {

    const outlet = useOutlet();
    const navigate = useNavigate()
    const user = decryptResponse(JSON.parse(reactLocalStorage.get('user')));
    const user_type = reactLocalStorage.getObject('user_type', {});
    // const [alive, setAlive] = useState(false)

    const token = reactLocalStorage.get('token')
    const isAuthenticated = reactLocalStorage.get('isAuthenticated')
    const is2FAVerified = reactLocalStorage.get('is2FAVerified')
    const revarc_available = reactLocalStorage.getObject('revarc_available', true);
    // useEffect(() => {
    //     if (!revarc_available && window.location.pathname != NON_REVARC_DASHBOARD) {
    //         navigate('/unauthorized')
    //     }

    // }, [])


    useEffect(() => {
        let alive = false
        let minute = 1;

        let timeout = setInterval(() => {
            if (alive) {
                keepAliveApi()((response) => {
                    if (response?.data) {
                        alive = false
                        minute = 1
                    }
                })
            }
            else {
                minute = minute + 1
            }
            // if(minute > process.env.REACT_APP_TIMEOUT_MINUTE){

            //     // Swal.fire({
            //     //     icon: 'error',
            //     //     confirmButtonColor : '#1ba1a6',
            //     //     title: 'Session Timeout',
            //     //     customClass: {
            //     //       title: 'swal-titleText'
            //     //     }
            //     //     // text: 'Session',
            //     //   }).then((result) => {


            //     //     localStorage.clear();
            //     //     window.location = "/";

            //     //   })
            // }
        }, 60000);


        const resetTimer = () => {
            if (!alive) {
                alive = true
            }

        };

        // Add event listeners to reset the timer on user interaction
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Clear the timer and remove event listeners when the component unmounts
        return () => {
            clearInterval(timeout);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
        };
    }, [window.location.pathname]);


    return (
        (!revarc_available && window.location.pathname != NON_REVARC_DASHBOARD && window.location.pathname != PROFILES ?
            <Navigate to={'/unauthorized'} replace />
            :
            window.location.pathname == NON_REVARC_DASHBOARD && user_type != 3 && user_type != 2  && user_type != 4 ?
                <Navigate to={'/unauthorized'} replace />
                :
                (isAuthenticated && token && user ?

                    // (user?.is_2fa_enable === true && Boolean(is2FAVerified) === true) || user?.is_2fa_enable === false ?
                        <div id="layout-wrapper">
                            <Header heading={heading} sync={sync} />
                            <NavBar />
                            <div className="vertical-overlay"></div>
                            <div className="main-content">
                                <div className="page-content">
                                    {outlet}
                                </div>
                            </div>
                        </div>
                        // :
                        // <Navigate to={LOGIN} replace />
                    :
                    // <Navigate to={LOGIN} replace />    
                    <></>
                )
        )

    )
}

export default PrivateLayout
